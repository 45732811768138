import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import tw from 'twin.macro'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1 } from '../../components/Headings'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

const HistorySlide = ({ data, active }) => {
  const { columns, year } = data

  return (
    <div
      css={[
        'min-height: 700px;',
        tw`items-center justify-center hidden relative overflow-hidden py-16`,
        active && tw`block`,
      ]}
    >
      <div
        css={[
          'top: 50%; left: 50%; font-size: 50vh;',
          tw`invisible sm:(visible) leading-none absolute transform -translate-x-1/2 -translate-y-1/2 font-condensed text-blue-500 opacity-50`,
        ]}
      >
        {year}
      </div>
      <Container tw="relative z-20 grid md:(grid-flow-col) auto-cols-fr col-gap-8">
        {columns.map(({ title, paragraph, image, imagePosition }) => {
          return (
            <div tw="flex justify-center items-center">
              <div
                css={[
                  tw`flex text-white w-full max-w-lg`,
                  imagePosition === 'top' && tw`flex-col-reverse`,
                  imagePosition === 'bottom' && tw`flex-col`,
                  imagePosition === 'left' &&
                    tw`flex-col-reverse md:(flex-row-reverse)`,
                  imagePosition === 'right' && tw`flex-row`,
                ]}
              >
                <div tw="sm:(w-full)">
                  {title ? <p tw="text-2xl uppercase mb-4">{title}</p> : null}
                  {paragraph ? (
                    <div
                      css={[
                        '& p { margin-bottom: 1rem; } & a { color: #fff; text-decoration: underline; }',
                        tw`text-gray-200 text-lg leading-relaxed mb-10`,
                      ]}
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    />
                  ) : null}
                </div>
                {image ? (
                  <>
                    <Image
                      fixed={image.localFile.childImageSharp.fixed}
                      css={[
                        tw`max-w-full border-8 border-white`,
                        imagePosition === 'top' && tw`mb-8`,
                        imagePosition === 'bottom' && tw`mt-8`,
                        imagePosition === 'left' && tw`mb-8 md:(mb-0 mr-8)`,
                        imagePosition === 'right' && tw`ml-8`,
                        // scale the image based on apsect ratio
                        320 < image.localFile.childImageSharp.fixed.width &&
                          tw`transform 
                                                            scale-90 
                                                            xl:(scale-100)`,
                      ]}
                    />
                    {/* <pre tw="text-white text-2xl font-bold">{JSON.stringify(image.localFile.childImageSharp.fixed, null, 2)}</pre> */}
                  </>
                ) : null}
              </div>
            </div>
          )
        })}
      </Container>
    </div>
  )
}

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const [activeSlide, setActiveSlide] = useState(0)
  const slides = page.companyHistory.timelineSlides
  const incrementActiveSlide = () =>
    activeSlide < slides.length ? setActiveSlide(activeSlide + 1) : null
  const decrementActiveSlide = () =>
    activeSlide - 1 > -1 ? setActiveSlide(activeSlide - 1) : null

  const arrowStyles = tw`text-blue-600 text-3xl`

  return (
    <>
      <SEO />
      <Layout>
        <section tw="py-8 text-center">
          <H1>{page.title}</H1>
          <div
            tw="prose max-w-full"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </section>

        {/* Slide Navigation */}
        <section tw="bg-gray-100">
          <Container tw="flex items-center justify-between">
            <button
              onClick={decrementActiveSlide}
              css={[tw`cursor-pointer`, activeSlide === 0 && tw`invisible`]}
            >
              <FontAwesomeIcon icon={faChevronLeft} css={arrowStyles} />
            </button>
            <nav tw="flex items-stretch py-8">
              {slides.map(({ year }, i) => {
                const isActive = i === activeSlide

                // hide the slide if it's outside the offset while on mobile.
                const mobileOffset =
                  activeSlide < 2 || activeSlide > slides.length - 3 ? 3 : 2
                const hideButtonOnMobile =
                  Math.abs(activeSlide - i) > mobileOffset
                const lastMobileButton = activeSlide - i === mobileOffset * -1

                return (
                  <button
                    onClick={() => setActiveSlide(i)}
                    css={[
                      tw`border-r-2 border-gray-200 z-30`,
                      i === slides.length - 1 && tw`border-0`,
                      isActive && tw`relative w-32`,
                      hideButtonOnMobile && tw`hidden lg:(flex)`,
                      lastMobileButton && tw`border-r-0`,
                    ]}
                  >
                    <div
                      css={[
                        tw`text-gray-500 text-lg md:(text-xl) font-bold px-2 py-1 leading-none`,
                        isActive &&
                          tw`text-4xl md:(text-5xl) text-blue-600 font-condensed absolute transform -translate-x-1/2 -translate-y-1/2`,
                        isActive && 'top:50%;left:50%;',
                      ]}
                    >
                      {year}
                    </div>
                    <div
                      css={[
                        'top: 100%; left: 50%;',
                        tw`w-10 h-10 rounded-full bg-blue-600 absolute flex items-center justify-center transform -translate-x-1/2 translate-y-1/2`,
                        !isActive && tw`hidden`,
                      ]}
                    >
                      <div tw="w-4 h-4 rounded-full bg-white" />
                    </div>
                  </button>
                )
              })}
            </nav>
            <button
              onClick={incrementActiveSlide}
              css={[
                tw`cursor-pointer`,
                activeSlide + 1 >= slides.length && tw`invisible`,
              ]}
            >
              <FontAwesomeIcon icon={faChevronRight} css={arrowStyles} />
            </button>
          </Container>
        </section>
        <section tw="bg-blue-600">
          {slides.map((slide, i) => (
            <HistorySlide data={slide} active={i === activeSlide} />
          ))}
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
        companyHistory {
          timelineSlides {
            year
            columns {
              title
              paragraph
              imagePosition
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 800) {
                      ...GatsbyImageSharpFixed_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
